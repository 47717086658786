// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ReactTransliterate_1b0d4b {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  text-align: left;
  z-index: 20000;
  width: 100%;
}

._ReactTransliterate_1b0d4b > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
  background-color: #fff;
}

._ReactTransliterate_1b0d4b > li:hover {
  background-color: red !important;
}

._Active_1b0d4b {
  background-color: red!important;
  color: yellow;
}

/*# sourceMappingURL=index.css.map */
`, "",{"version":3,"sources":["webpack://./src/IndicTransliterate/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,qCAAqC;EACrC,2CAA2C;EAC3C,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;EAC/B,aAAa;AACf;;AAEA,oCAAoC","sourcesContent":["._ReactTransliterate_1b0d4b {\n  background-clip: padding-box;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);\n  display: block;\n  font-size: 14px;\n  list-style: none;\n  padding: 1px;\n  text-align: left;\n  z-index: 20000;\n  width: 100%;\n}\n\n._ReactTransliterate_1b0d4b > li {\n  cursor: pointer;\n  padding: 10px;\n  min-width: 100px;\n  background-color: #fff;\n}\n\n._ReactTransliterate_1b0d4b > li:hover {\n  background-color: red !important;\n}\n\n._Active_1b0d4b {\n  background-color: red!important;\n  color: yellow;\n}\n\n/*# sourceMappingURL=index.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
